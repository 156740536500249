<template>
    <content-view>
        <idea
            :idea-id="$route.params.id"
        />
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import Idea from '@/components/ideas/detail/Idea'
import { metaTitle } from '@/mixins/meta_title'

export default {
    name: 'IdeaPage',
    mixins: [metaTitle],
    components: { Idea, ContentView }
}
</script>

<style scoped>

</style>
